import React, { useState, Fragment } from "react";
import DeliveryData from "./DeliveryData";
import DeliveryForm from "./DeliveryForm";
import { useParams } from "react-router-dom";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import TeamNavBar from "../TeamPages/TeamNavBar";

function DeliveryPage(props) {
  const { sitesRef, serverTimestamp, auth, userTeams, getUsersFirstName } = props;

  const { teamId } = useParams();
  const userTeamsReference = [...userTeams];
  const [showDeliveryForm, setShowDeliveryForm] = useState(false);
  const [selectedSite, setSelectedSite] = useState(
    teamId !== undefined ? teamId : userTeamsReference[0]
  );

  const viewSiteDeliveriesRef = sitesRef
    .doc(selectedSite)
    .collection("deliverys");
  const membersRef = sitesRef.doc(selectedSite).collection("members");  

  if (
    userTeamsReference.indexOf("POR01") > -1 &&
    userTeamsReference.indexOf("POR02") < 0
  ) {
    userTeamsReference.push("POR02");
  }

  return (
    <div className={`${showDeliveryForm === true ? "blur-sm" : ""}`}>
      <header className="bg-white shadow max-h-10">
        <TeamNavBar teamId={teamId} />
      </header>
      <main>
        <div className="pt-8 flex-row flex justify-center">
          {userTeamsReference.length > 1 ? (
            <div className="flex flex-row">
              <Listbox value={selectedSite} onChange={setSelectedSite}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative  cursor-default rounded-sm bg-white py-2 pl-3 text-slate-800 pr-10   text-left shadow focus:outline-none focus-visible:border-indigo-600 focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                    <span className="block truncate">{selectedSite}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 max-w-lg overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {userTeamsReference.map((team, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active
                                ? "bg-indigo-100 text-slate-900"
                                : "text-gray-900"
                            }`
                          }
                          value={team}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {team}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>

              <button
                className=" bg-indigo-600 text-white hover:bg-indigo-500 rounded-sm shadow-md px-1 h-9 mt-1 items-center text-center ml-20 active:scale-95"
                onClick={() => setShowDeliveryForm(true)}
              >
                New Delivery
              </button>
            </div>
          ) : (
            <button
              className=" bg-indigo-600 text-white hover:bg-indigo-500 rounded-sm shadow-md p-1 items-center text-center"
              onClick={() => setShowDeliveryForm(true)}
            >
              New Delivery
            </button>
          )}
        </div>

        <DeliveryForm
          viewSiteDeliveriesRef={viewSiteDeliveriesRef}
          sitesRef={sitesRef}
          serverTimestamp={serverTimestamp}
          setShowDeliveryForm={setShowDeliveryForm}
          auth={auth}
          showDeliveryForm={showDeliveryForm}
          getUsersFirstName={getUsersFirstName}
        />

        <DeliveryData
          viewSiteDeliveriesRef={viewSiteDeliveriesRef}
          selectedSite={selectedSite}
          membersRef={membersRef}
          
        />
      </main>
    </div>
  );
}

export default DeliveryPage;
