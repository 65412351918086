import React from "react";
import { Link, useLocation } from "react-router-dom";

function TeamNavBar(props) {
  const { teamId } = props;

  const location = useLocation();

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      <div className="mx-auto max-w-7xl py-1 px-4 sm:px-4 lg:px-8 justify-center md:justify-between flex">
      <h1 className="hidden md:inline text-2xl font-bold tracking-normal  text-gray-900">
          Team Pages
        </h1>
        <div className="justify-center items-center pt-1">
          <Link
            className={classNames(
              location.pathname === `/OpenTickets/${teamId}`
                ? "bg-gray-300 shadow-md rounded-sm p-1 mr-2 text-black"
                : "hover:bg-gray-200 hover:shadow-md",
              "bg-gray-100 shadow-sm mr-2 rounded-sm p-1 text-gray-800 active:scale-95"
            )}
            to={`/OpenTickets/${teamId}`}
          >
            Open Tickets
          </Link>
          <Link
            className={classNames(
              location.pathname === `/CompletedTickets/${teamId}`
                ? "bg-gray-300 shadow-md rounded-sm p-1 mr-2 text-black"
                : "hover:bg-gray-200 hover:shadow-md",
              "bg-gray-100 shadow-sm mr-2 rounded-sm p-1 text-gray-800 active:scale-95"
            )}
            to={`/CompletedTickets/${teamId}`}
          >
            Closed Tickets
          </Link>
          <Link
            className={classNames(
              location.pathname === `/Deliveries/${teamId}`
                ? "bg-gray-300 shadow-md rounded-sm p-1 mr-2 text-black"
                : "hover:bg-gray-200 hover:shadow-md",
              "bg-gray-100 shadow-sm mr-2 rounded-sm p-1 text-gray-800 active:scale-95"
            )}
            to={`/Deliveries/${teamId}`}
          >
            Deliveries
          </Link>
        </div>
      </div>
    </div>
  );
}

export default TeamNavBar;
