import React, { useState, useEffect } from "react";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import TaskList from "./components/OpenTickets/TaskList";
import CompletedTasks from "./components/ClosedTickets/CompletedTasks";
import { useAuthState } from "react-firebase-hooks/auth";
import PopupForm from "./components/OpenTickets/PopupForm";
import DeliveryPage from "./components/Deliveries/DeliveryPage";
import TeamOpenTickets from "./components/TeamPages/TeamOpenTickets";
import TeamCompletedTickets from "./components/TeamPages/TeamCompletedTIckets";
import JoinTeamForm from "./components/JoinLink/JoinTeamForm";
import NewNavBar from "./components/NavBar/NewNavBar";
import NewSignIn from "./components/Signin/NewSignIn";
import TeamMembers from "./components/TeamPages/TeamMembers";
import Alert from "./components/Alert";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

const auth = firebase.auth();
const firestore = firebase.firestore();
const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
const patchpanelsRef = firestore.collection("patchpanels");
const usersRef = firestore.collection("Users");
const sitesRef = firestore.collection("sites");
const oldTicketsRef = firestore.collection("tickets");

//const authRef = firestore.collection('auth')

export function TaskTracker() {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [user] = useAuthState(auth);
  const [selectedTask, setSelectedTask] = useState({
    task: "",
    showNotes: false,
  });
  const [newSubject, setNewSubject] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userTeams, setUserTeams] = useState([]);
  const [viewPatchPanels, setViewPatchPanels] = useState(false);
  const [IsInTeam, setIsInTeam] = useState(false);
  const [showJoinTeamForm, setShowJoinTeamForm] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [filteredTasks, setFilteredTasks] = useState([]);

  useEffect(() => {
    if (auth.currentUser) {
      isUserAdmin().then((result) => {
        setIsAdmin(result);
        checkUserTeams(auth.currentUser.uid)
          .then((teams) => {
            setUserTeams([...teams]);
          })
          .catch((error) => {
            console.log("Error getting teams: ", error);
          });
      });
    }
  }, [auth.currentUser]);

  useEffect(() => {
    if (userTeams.length > 0) {
      setIsInTeam(true);
      setSelectedTeam(userTeams[0]);
    }
  }, [userTeams]);

  if (user) {
    // User is signed in.
    localStorage.setItem("isUserSignedIn", true);
  } else {
    // No user is signed in.
    localStorage.setItem("isUserSignedIn", false);
  }

  const isUserSignedIn = localStorage.getItem("isUserSignedIn");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { uid } = auth.currentUser;
    const ticketsRef = usersRef.doc(auth.currentUser.uid).collection("tickets");

    if (newTask) {
      setTasks([
        ...tasks,
        { task: newTask, url: newUrl, notes: "", showNotes: false },
      ]);
      const ticket = {
        submitNumber: newTask,
        submitUrl:
          "https://edgeos.edgeconnex.com/portal/Forms/ShowRequest.aspx?ID_FINDER=" +
          newTask,
        submitSubject: newSubject,
        submitNotes: "",
        submitStatus: 1,
        submitSubmitter: uid,
        submitteddate: firebase.firestore.FieldValue.serverTimestamp(),
        backgroundColor: "hidden",
        selectedCheckbox: null,
        teamTicket: false,
        teamTicket: false,
      };
      await ticketsRef.add(ticket);
      setNewTask("");
      setNewUrl("");
      setNewSubject("");
    }
    setShowForm(false);
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(provider)
      .then((result) => {
        const user = result.user;
        const profile = result.additionalUserInfo.profile;
        const userId = user.uid;
        const userEmail = user.email;
        const userFirstName = profile.given_name;
        const userLastName = profile.family_name;
        usersRef
          .doc(userId)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              saveUserToDatabase(
                userId,
                userEmail,
                userFirstName,
                userLastName
              );
            } else {
              console.log("User already exists");
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function sendVerificationEmail() {
    const user = auth.currentUser;
    try {
      await user.sendEmailVerification();
      console.log("Email sent");
    } catch (error) {
      console.log(error);
    }
  }

  const sendPasswordResetEmail = (email) => {
    auth.sendPasswordResetEmail(email);
  };

  const saveUserToDatabase = (userId, newEmail, firstName, lastName) => {
    usersRef.doc(userId).set({
      email: newEmail,
      firstName: firstName,
      lastName: lastName,
      teams: [],
      permissions: [],
    });
  };

  const handleTaskChange = (event) => {
    setNewTask(event.target.value);
  };

  function SignOut() {
    return auth.currentUser && auth.signOut();
  }

  const handleSubjectChange = (event) => {
    setNewSubject(event.target.value);
  };

  const handleUrlChange = (event) => {
    setNewUrl(event.target.value);
  };

  const handleNotesChange = (index, event) => {
    const currentTask = tasks[index];
    const updatedTask = {
      ...currentTask,
      submitNotes: event.target.value,
    };

    setTasks([
      ...tasks.slice(0, index),
      updatedTask,
      ...tasks.slice(index + 1),
    ]);
  };

  const handleTaskClick = (task) => {
    let updatedTasks = tasks.map((t) => ({
      ...t,
      showNotes: false,
    }));

    const currentTask = tasks.find((t) => t.id === task.id);
    const index = tasks.indexOf(currentTask);

    const updatedTask = {
      ...currentTask,
      showNotes: selectedTask.id === task.id ? !currentTask.showNotes : true,
    };
    updatedTasks[index] = updatedTask;
    setTasks(updatedTasks);

    const updatedSelectedTask = {
      showNotes: selectedTask.id === task.id ? !currentTask.showNotes : true,
      task: currentTask.task,
      notes: currentTask.notes,
      url: currentTask.url,
      id: currentTask.id,
    };
    setSelectedTask(updatedSelectedTask);
  };

  function handleCompletedTaskClick(task) {
    if (selectedTask.task === task.task) {
      setSelectedTask({
        showNotes: false,
        task: null,
        notes: null,
        url: null,
      });
    } else {
      setSelectedTask({
        showNotes: true,
        task: task.task,
        notes: task.notes,
        url: task.url,
      });
    }
  }

  const handleCompleteTask = async (task) => {
    const ticketsRef = usersRef.doc(auth.currentUser.uid).collection("tickets");
    const currentTask = task.id;
    setCompletedTasks([...completedTasks, currentTask]);
    setSelectedTask({
      showNotes: false,
      task: null,
      notes: null,
      url: null,
    });
    await ticketsRef.doc(currentTask).update({
      submitStatus: 2,
      completedate: firebase.firestore.FieldValue.serverTimestamp(),
    });
    if (task.teamTicket === true) {
      if (task.teamDocId === null || task.teamDocId === undefined) {
        console.log("teamDocId is null");
      }
      await sitesRef
        .doc(task.siteId)
        .collection("tickets")
        .doc(task.teamDocId)
        .update({
          submitStatus: 2,
          completedBy: auth.currentUser.displayName,
          completedate: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
  };

  async function isUserAdmin() {
    let uidAuth = auth.currentUser.uid;
    const doc = await usersRef.doc(uidAuth).get();
    if (doc.exists) {
      if (doc.data().permissions.includes("admin")) {
        console.log("user has admin permissions");
        return true;
      } else {
        console.log("user does not have admin permissions");
        return false;
      }
    } else {
      console.log("No such document!");
    }
  }

  async function checkUserTeams() {
    let uidAuth = auth.currentUser.uid;
    const doc = await usersRef.doc(uidAuth).get();
    if (doc.exists) {
      const teams = doc.data().teams;
      console.log(teams);
      if (teams.indexOf("POR01") > -1) {
      }
      if (teams.length > 0) {
        setIsInTeam(true);
      }
      setUserTeams(teams);
      return teams;
    } else {
      console.log("No user document!");
    }
  }

  async function checkUserTeamRole(team, checkUid) {
    const membersList = await sitesRef
      .doc(team)
      .collection("members")
      .doc(checkUid)
      .get();
    if (membersList.exists) {
      const permissions = membersList.data().role;
      console.log(permissions);
      return permissions;
    } else {
      console.log("User is not in any teams!");
    }
  }

  async function getUsersFirstName(uid) {
    let uidAuth = uid;
    const doc = await usersRef.doc(uidAuth).get();
    if (doc.exists) {
      let displayName = doc.data().firstName;
      console.log(displayName);
      return displayName;
    } else {
      console.log("No such document!");
    }
  }

  // edit to change icon
  // function returns users name from uid from firestore users collection

  return (
    <div className="">
      {isUserSignedIn === "true" ? (
        <div>
          
            <>
              <NewNavBar
                TaskList={TaskList}
                userTeams={userTeams}
                completedTasks={CompletedTasks}
                SignOut={SignOut}
                isAdmin={isAdmin}
                viewPatchPanels={viewPatchPanels}
                IsInTeam={IsInTeam}
                setShowJoinTeamForm={setShowJoinTeamForm}
                selectedTeam={selectedTeam}
                sitesRef={sitesRef}
                auth={auth}
                showJoinTeamForm={showJoinTeamForm}
              />
              <JoinTeamForm
                showJoinTeamForm={showJoinTeamForm}
                setShowJoinTeamForm={setShowJoinTeamForm}
                auth={auth}
                sitesRef={sitesRef}
                getUsersFirstName={getUsersFirstName}
                usersRef={usersRef}
                arrayUnion={arrayUnion}
              />
              <div
                className={`pt-16 ${
                  showJoinTeamForm === true ? "blur-sm" : ""
                }`}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      <div>
                        <div className="bg-white w-full shadow max-h-10 overflow-hidden">
                          <div className="w-fit">
                            <div className="flex-row">
                              <h1 className="text-2xl font-bold justify-start tracking-normal text-gray-900">
                                Open Tickets
                              </h1>
                            </div>
                          </div>
                        </div>
                        <main>
                          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
                            <PopupForm
                              showForm={showForm}
                              newTask={newTask}
                              newSubject={newSubject}
                              newUrl={newUrl}
                              handleSubmit={handleSubmit}
                              handleTaskChange={handleTaskChange}
                              handleUrlChange={handleUrlChange}
                              handleSubjectChange={handleSubjectChange}
                              setShowForm={setShowForm}
                              checkUserTeams={checkUserTeams}
                              tasks={tasks}
                              setFilteredTasks={setFilteredTasks}
                              filteredTasks={filteredTasks}
                            />
                            <TaskList
                              showForm={showForm}
                              auth={auth}
                              tasks={tasks}
                              setTasks={setTasks}
                              handleTaskClick={handleTaskClick}
                              handleNotesChange={handleNotesChange}
                              handleCompleteTask={handleCompleteTask}
                              oldTicketsRef={oldTicketsRef}
                              usersRef={usersRef}
                              isAdmin={isAdmin}
                              filteredTasks={filteredTasks}
                            />
                          </div>
                        </main>
                      </div>
                    }
                  />
                  <Route
                    path="/CompletedTickets"
                    element={
                      <div>
                        <header className="bg-white shadow w-full flex flex-wrap max-h-10">
                          <div className="mx-auto max-w-7xl py-1 px-4 sm:px-6 lg:px-8">
                            <h1 className="text-2xl font-bold tracking-normal text-gray-900">
                              Completed Tickets
                            </h1>
                          </div>
                        </header>
                        <main>
                          <CompletedTasks
                            auth={auth}
                            usersRef={usersRef}
                            completedTasks={completedTasks}
                            selectedTask={selectedTask}
                            handleCompletedTaskClick={handleCompletedTaskClick}
                            handleTaskClick={handleTaskClick}
                          />
                        </main>
                      </div>
                    }
                  />
                  {IsInTeam && (
                    <Route
                      path="/Deliveries/:teamId"
                      element={
                        <DeliveryPage
                          sitesRef={sitesRef}
                          auth={auth}
                          userTeams={userTeams}
                          getUsersFirstName={getUsersFirstName}
                        />
                      }
                    />
                  )}
                  {IsInTeam && (
                    <Route
                      path={"/OpenTickets/:teamId"}
                      element={
                        <TeamOpenTickets
                          auth={auth}
                          userTeams={userTeams}
                          sitesRef={sitesRef}
                          checkUserTeamRole={checkUserTeamRole}
                          usersRef={usersRef}
                        />
                      }
                    />
                  )}
                  {IsInTeam && (
                    <Route
                      path={"/CompletedTickets/:teamId"}
                      element={
                        <TeamCompletedTickets
                          auth={auth}
                          userTeams={userTeams}
                          sitesRef={sitesRef}
                        />
                      }
                    />
                  )}
                  <Route
                    path={"/TeamMembers/:teamId"}
                    element={<TeamMembers />}
                  />
                </Routes>
              </div>
            </>
        </div>
      ) : (
        <>
          <NewSignIn
            signInWithGoogle={signInWithGoogle}
            sendPasswordResetEmail={sendPasswordResetEmail}
            sendVerificationEmail={sendVerificationEmail}
            auth={auth}
            saveUserToDatabase={saveUserToDatabase}
          />
        </>
      )}
      <Alert
        triggerOpen={verificationEmailSent}
        setTriggerOpen={setVerificationEmailSent}
        alertTitle="Verification Email Sent"
        alertMessage="If you don't see the email in your inbox check your spam folder"
      />
    </div>
  );
}

export default TaskTracker;
