import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import TeamTaskNotes from "./TeamTaskNotes";
import TeamPopupForm from "./TeamPopupForm";
import TeamNavBar from "./TeamNavBar";

function TeamOpenTickets(props) {
  const { userTeams, auth, sitesRef, checkUserTeamRole, usersRef } = props;

  let { teamId } = useParams();
  const [teamTickets, setTeamTickets] = useState([]);
  const [ticketNumberEdit, setTicketNumberEdit] = useState("");
  const [taskEditId, setTaskEditId] = useState(null);
  const [ticketSubjectEdit, setTicketSubjectEdit] = useState("");
  const [selectedTeamTask, setSelectedTeamTask] = useState({
    task: "",
    showNotes: false,
  });
  const [teamCompletedTasks, setTeamCompletedTasks] = useState([]);
  const [teamTechs, setTeamTechs] = useState([]);
  const [techAssigned, setTechAssigned] = useState("Unassigned");
  const [teamRole, setTeamRole] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [filteredTeamTasks, setFilteredTeamTasks] = useState([]);

  const teamTicketsRef = sitesRef.doc(teamId).collection("tickets");

  useEffect(() => {
    const fetchData = async () => {
      await sitesRef
        .doc(teamId)
        .collection("members")
        .where("role", "==", "Tech")
        .onSnapshot((snapshot) => {
          const techs = snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              name: doc.data().name,
            };
          });
          setTeamTechs(techs);
        });
      const role = await checkUserTeamRole(teamId, auth.currentUser.uid);
      setTeamRole(role);
    };
    fetchData();
  }, [teamId, auth.currentUser.uid, checkUserTeamRole, sitesRef]);

  useEffect(() => {
    if (userTeams.indexOf(teamId) > -1) {
      const unsubscribe = sitesRef
        .doc(teamId)
        .collection("tickets")
        .where("submitStatus", "==", 1)
        .onSnapshot((snapshot) => {
          const newTicketList = snapshot.docs.map((doc) => {
            return {
              id: doc.id,
              assignedTo: doc.data().assignedTo,
              backgroundColor: doc.data().backgroundColor,
              new: doc.data().new,
              selectedCheckbox: doc.data().selectedCheckbox,
              submitNotes: doc.data().submitNotes,
              submitNumber: doc.data().submitNumber,
              submitStatus: doc.data().submitStatus,
              submitSubject: doc.data().submitSubject,
              submitSubmitter: doc.data().submitSubmitter,
              submitUrl: doc.data().submitUrl,
              showNotes: false,
              submitteddate: doc.data().submitteddate.toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }),
            };
          });
          const sortedTasks = newTicketList.sort((a, b) => {
            return a.submitNumber - b.submitNumber;
          });
          setTeamTickets(sortedTasks);
        });
      return () => unsubscribe();
    } else {
      return (
        <div className="flex justify-center items-center h-screen w-screen">
          Your not assigned to this team or this this team doesn't exsist
        </div>
      );
    }
  }, [teamId, sitesRef, userTeams]);

  function handleEdit(id) {
    setTaskEditId(id);
    const currentPanel = teamTickets.find((task) => task.id === id);
    setTicketNumberEdit(currentPanel.submitNumber);
    setTicketSubjectEdit(currentPanel.submitSubject);
  }

  function handleDelete(id) {
    const docRef = teamTicketsRef.doc(id);
    docRef
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  }

  async function handleEditSave() {
    try {
      await teamTicketsRef.doc(taskEditId).update({
        submitNumber: ticketNumberEdit,
        submitSubject: ticketSubjectEdit,
      });
      setTicketNumberEdit("");
      setTaskEditId(null);
      setTicketSubjectEdit("");
    } catch (err) {
      console.log("Error updating document", err);
    }
  }

  function handleChange(e) {
    setTicketNumberEdit(e.target.value);
  }

  function handleSubjectChange(e) {
    setTicketSubjectEdit(e.target.value);
  }

  const handleTaskClick = (index) => {
    const currentTask = teamTickets[index];
    let updatedTasks = teamTickets.map((task) => ({
      ...task,
      showNotes: false,
    }));

    const updatedTask = {
      ...currentTask,
      showNotes:
        selectedTeamTask.task === currentTask.task
          ? !currentTask.showNotes
          : true,
    };
    updatedTasks[index] = updatedTask;
    setTeamTickets(updatedTasks);

    const updatedSelectedTask = {
      showNotes:
        selectedTeamTask.task === currentTask.task
          ? !currentTask.showNotes
          : true,
      task: currentTask.task,
      notes: currentTask.notes,
      url: currentTask.url,
    };
    setSelectedTeamTask(updatedSelectedTask);
  };

  const handleCheckboxChange = async (index, number) => {
    const updatedTasks = [...teamTickets];
    const task = updatedTasks[index];
    if (number === 1) {
      if (task.backgroundColor === "bg-color3") {
        task.selectedCheckbox = null;
        task.backgroundColor = "hidden";
      } else {
        task.selectedCheckbox = "1";
        task.backgroundColor = "bg-color3";
      }
    } else {
      if (task.backgroundColor === "bg-color4") {
        task.selectedCheckbox = null;
        task.backgroundColor = "hidden";
      } else {
        task.selectedCheckbox = "2";
        task.backgroundColor = "bg-color4";
      }
    }
    setTeamTickets(updatedTasks);
    try {
      await teamTicketsRef.doc(task.id).update({
        backgroundColor: task.backgroundColor,
        selectedCheckbox: number,
      });
    } catch (error) {
      console.error("Error updating task: ", error);
    }
  };

  const handleCompleteTask = async (recivedTask) => {
    const currentTask = recivedTask;
    setTeamTickets(teamTickets.filter((task) => task.id !== currentTask.id));
    setTeamCompletedTasks([...teamCompletedTasks, currentTask]);
    setSelectedTeamTask({
      showNotes: false,
      task: null,
      notes: null,
      url: null,
    });
    await teamTicketsRef.doc(currentTask.id).update({
      submitStatus: 2,
      completedBy: auth.currentUser.displayName,
      completedate: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  const handleTechAssign = async (currentTask, tech, claimed) => {
    let techName = "";
    let techId = "";
    if (claimed) {
      const doc = await usersRef.doc(tech).get();
      if (doc.exists) {
        techName = doc.data().firstName;
        techId = tech;
      } else {
        console.log("error finding tech");
        return;
      }
    } else {
      const selectedTech = teamTechs[tech];
      techName = selectedTech.name;
      techId = selectedTech.id;
    }

    if (currentTask.assignedTo === techName) {
      alert("This ticket is already assigned to this tech");
      return;
    }
    try {
      await teamTicketsRef.doc(currentTask.id).update({
        assignedTo: techName,
      });
    } catch (error) {
      console.log(techName);
      console.error("Error assigning tech: ", error);
      alert("Error assigning tech");
    }
    try {
      await usersRef
        .doc(techId)
        .collection("tickets")
        .add({
          ...currentTask,
          teamTicket: true,
          siteId: teamId,
          teamDocId: currentTask.id,
        });
    } catch (error) {
      console.error("Error adding ticket to tech: ", error);
      alert("Error assigning ticket to tech");
    }
  };

  return (
    <div className={`${showForm === true ? "blur-sm" : ""}`}>
      <header className="bg-white shadow w-full max-h-10">
        <TeamNavBar teamId={teamId} auth={auth} />
      </header>
      <main>
        <div className="pt-8">
          <TeamPopupForm
            teamId={teamId}
            showForm={showForm}
            setShowForm={setShowForm}
            auth={auth}
            teamTicketsRef={teamTicketsRef}
            teamTickets={teamTickets}
            setTeamTickets={setTeamTickets}
            filteredTeamTasks={filteredTeamTasks}
            setFilteredTeamTasks={setFilteredTeamTasks}
          />
          <div className="pt-2 flex flex-col text-center items-center ">
            <ul className="w-600 tasklist">
              {filteredTeamTasks.map((task, index) => (
                <li
                  key={task.id}
                  className={`
                  flex items-center justify-center 
                  ${
                    task.showNotes
                      ? "bg-indigo-600 text-white"
                      : "bg-white text-gray-700"
                  }
                  hover:bg-indigo-600 hover:text-white shadow rounded-sm cursor-pointer m-3 p-2
                `}
                  onClick={() => handleTaskClick(index)}
                >
                  <div
                    className="flex flex-col "
                    style={task.showNotes ? { display: "none" } : {}}
                  >
                    <div className="flex justify-between">
                      {teamRole === "Manager" ? (
                        <select
                          className="rounded ml-1 mr-2 text-gray-700 h-6 border-indigo-600 border-2 w-32 p-0 pl-2"
                          value={task.assignedTo}
                          onClick={(event) => event.stopPropagation()}
                          onChange={(e) =>
                            handleTechAssign(task, e.target.value, false)
                          }
                        >
                          {task.assignedTo === "Unassigned" ? (
                            <option value="Unassigned">Unassigned</option>
                          ) : (
                            <option value={task.assignedTo}>
                              {task.assignedTo}
                            </option>
                          )}
                          {teamTechs.map((tech, index) => {
                            if (tech.name === task.assignedTo) {
                              return null;
                            }
                            return (
                              <option key={tech.id} value={index}>
                                {tech.name}
                              </option>
                            );
                          })}
                        </select>
                      ) : (
                        <div className="">
                          {task.assignedTo === "Unassigned" ? (
                            <div className="flex w-24">
                            <button
                              className="rounded ml-1 mr-2  hover:text-gray-700 h-7 text-center active:scale-95 border-white border-2  hover:bg-white text-white bg-indigo-600 w-14 p-0 "
                              onClick={() =>
                                handleTechAssign(
                                  task,
                                  auth.currentUser.uid,
                                  true
                                )
                              }
                            >
                              Claim
                            </button>
                            </div>
                          ) : (
                            <div className="text-left w-24 mr-10 md:mr-0">
                            <span >
                              {task.assignedTo}
                            </span>
                            </div>
                          )}
                        </div>
                      )}

                      <span className=" w-24 mr-16  ml-8 md:ml-32 md:mr-32 font-semibold tracking-wide ">
                        {task.submitNumber}
                      </span>
                      <div className="w-24 ">
                      <div
                        className={` box ml-20  rounded ${task.backgroundColor} `} />

                      </div>
                    </div>
                    <span>{task.submitSubject}</span>
                  </div>

                  {task.showNotes && (
                    <div className="flex flex-col">
                      <div className="justify-center flex-row">
                        {taskEditId === task.id ? (
                          <div className="flex ml-1 mr-1">
                            <input
                              autoFocus
                              className="rounded mr-2 text-gray-700 pr-1 w-38 text-center"
                              value={ticketNumberEdit}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") handleEditSave();
                              }}
                              onClick={(event) => event.stopPropagation()}
                              onChange={handleChange}
                            />

                            <input
                              className="rounded mr-2 text-gray-700 pr-1 w-38 text-center"
                              value={ticketSubjectEdit}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") handleEditSave();
                              }}
                              onClick={(event) => event.stopPropagation()}
                              onChange={handleSubjectChange}
                            />
                          </div>
                        ) : (
                          <div className="flex justify-between">
                            {teamRole === "Manager" ? (
                              <select
                                className="rounded text-gray-700 h-6 border-indigo-600 border-2 w-24 p-0 pl-2"
                                value={techAssigned}
                                onClick={(event) => event.stopPropagation()}
                                onChange={(e) =>
                                  setTechAssigned(e.target.value)
                                }
                              >
                                {task.assignedTo === "Unassigned" ? (
                                  <option value="Unassigned">Unassigned</option>
                                ) : (
                                  <option value={task.assignedTo}>
                                    {task.assignedTo}
                                  </option>
                                )}
                                {teamTechs.map((tech, index) => (
                                  <option key={tech.id} value={index}>
                                    {tech.name}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <span className=" text-left w-24  ">
                                {task.assignedTo}
                              </span>
                            )}
                            <span className=" w-24 font-semibold tracking-wide ">
                              {task.submitNumber}
                            </span>
                            <div className="w-24">
                              <div
                                className={` ml-20 box  rounded ${task.backgroundColor} `}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div>{task.submitSubject}</div>

                      {task.submitUrl && (
                        <div className=" justify-center">
                          <div className=" flex justify-center">
                            <a
                              className="w-24"
                              href={task.submitUrl}
                              onClick={(event) => event.stopPropagation()}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Go To Ticket
                            </a>
                          </div>
                          <div className="justify-end pb-1 ml-2">
                            <button
                              className=" bg-red-500 w-5 h-6 rounded pl-1 pr-1"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleCheckboxChange(index, 1);
                              }}
                            ></button>
                            <button
                              className="bg-yellow-500 rounded w-5 h-6 ml-1 pl-1 pr-1"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleCheckboxChange(index, 2);
                              }}
                            ></button>
                          </div>
                        </div>
                      )}

                      <TeamTaskNotes
                        task={task.id}
                        teamTicketsRef={teamTicketsRef}
                      />

                      <div className="flex flex-row m-1 justify-between">
                        <button
                          className="active:scale-95 group relative px-1 justify-start rounded-sm h-7 text-center shadow-sm tracking-normal hover:bg-slate-300 hover:text-black bg-slate-200 text-gray-700 mt-1"
                          onClick={(event) => {
                            handleDelete(task.id);
                            event.stopPropagation();
                          }}
                        >
                          Delete
                        </button>
                        <button
                          className="active:scale-95 group flex ml-6 relative px-1 rounded-sm h-7 text-center shadow-sm  items-center tracking-normal hover:bg-slate-300 hover:text-black bg-slate-200 text-gray-700 mt-1"
                          onClick={(event) => {
                            handleEdit(task.id);
                            event.stopPropagation();
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="active:scale-95 group relative px-1 flex items-center rounded-sm h-7 text-center shadow-sm tracking-normal hover:bg-slate-300 hover:text-black bg-slate-200 text-gray-700 mt-1"
                          onClick={() => handleCompleteTask(task)}
                        >
                          Complete
                        </button>
                      </div>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
}

export default TeamOpenTickets;
