import React, { useState } from "react";
import { useEffect } from "react";
import TaskNotes from "./TaskNotes";

const TaskList = (props) => {
  const {
    tasks,
    setTasks,
    handleTaskClick,
    handleCompleteTask,
    auth,
    usersRef,
    showForm,
    filteredTasks,
  } = props;

  const [ticketNumberEdit, setTicketNumberEdit] = useState("");
  const [taskEditId, setTaskEditId] = useState(null);
  const [ticketSubjectEdit, setTicketSubjectEdit] = useState("");
  const usersTicketsRef = usersRef
    .doc(auth.currentUser.uid)
    .collection("tickets");

  useEffect(() => {
    const unsubscribe = usersRef
      .doc(auth.currentUser.uid)
      .collection("tickets")
      .where("submitStatus", "==", 1)
      .onSnapshot((snapshot) => {
        const newTasks = snapshot.docs.map((doc) => ({
          id: doc.id,
          submitNumber: doc.data().submitNumber,
          submitUrl: doc.data().submitUrl,
          submitNotes: doc.data().submitNotes,
          submitSubject: doc.data().submitSubject,
          backgroundColor: doc.data().backgroundColor,
          selectedCheckbox: doc.data().selectedCheckbox,
          submitteddate: doc.data().submitteddate,
          showNotes: false,
          teamticket: doc.data().teamticket,
          siteId: doc.data().siteId,
          teamDocId: doc.data().teamDocId,
        }));
        console.log("triggered");
        const sortedTasks = newTasks.sort((a, b) => {
          return a.submitNumber - b.submitNumber;
        });
        setTasks(sortedTasks);
      });
    return unsubscribe;
  }, [usersRef, setTasks, auth.currentUser.uid]);

  function handleEdit(id) {
    setTaskEditId(id);
    const currentPanel = tasks.find((task) => task.id === id);
    setTicketNumberEdit(currentPanel.submitNumber);
    setTicketSubjectEdit(currentPanel.submitSubject);
  }

  function handleDelete(id) {
    const docRef = usersTicketsRef.doc(id);
    docRef
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  }

  async function handleEditSave() {
    try {
      await usersTicketsRef.doc(taskEditId).update({
        submitNumber: ticketNumberEdit,
        submitSubject: ticketSubjectEdit,
      });
      setTicketNumberEdit("");
      setTaskEditId(null);
      setTicketSubjectEdit("");
    } catch (err) {
      console.log("Error updating document", err);
    }
  }

  function handleChange(e) {
    setTicketNumberEdit(e.target.value);
  }

  function handleSubjectChange(e) {
    setTicketSubjectEdit(e.target.value);
  }

  const handleCheckboxChange = async (index, number) => {
    const updatedTasks = [...tasks];
    const task = updatedTasks[index];
    console.log(task.selectedCheckbox);
    if (number === 1) {
      if (task.backgroundColor === "bg-color3") {
        task.selectedCheckbox = null;
        task.backgroundColor = "hidden";
        console.log("option one null");
      } else {
        task.selectedCheckbox = 1;
        task.backgroundColor = "bg-color3";
        console.log("option one");
      }
    } else {
      if (task.backgroundColor === "bg-color4") {
        task.selectedCheckbox = null;
        task.backgroundColor = "hidden";
        console.log("option two null");
      } else {
        task.selectedCheckbox = 2;
        task.backgroundColor = "bg-color4";
        console.log("option two");
      }
    }
    setTasks(updatedTasks);
    try {
      await usersTicketsRef.doc(task.id).update({
        backgroundColor: task.backgroundColor,
        selectedCheckbox: number,
      });
    } catch (error) {
      console.error("Error updating task: ", error);
    }
  };

  return (
    <div
      className={`pt-2 flex flex-col text-center items-center ${
        showForm === true ? "blur-sm" : ""
      } `}
    >
      <ul className="w-600 tasklist">
        {filteredTasks.map((task, index) => (
          <li
            key={task.id}
            className={`
            flex items-center justify-center 
            ${
              task.showNotes
                ? "bg-indigo-600 text-white"
                : "bg-white text-gray-700"
            }
            hover:bg-indigo-600 hover:text-white shadow rounded-sm cursor-pointer m-3 p-2
          `}
            onClick={() => handleTaskClick(task)}
          >
            <div
              className="flex items-center justify-center"
              style={task.showNotes ? { display: "none" } : {}}
            >
              <span className="ml-24 md:ml-36 tfont-semibold tracking-wide w-64">
                {task.submitNumber + ": " + task.submitSubject}
              </span>
              {task.selectedCheckbox !== null ? (
                <div
                  className={` box ml-20 md:ml-32 rounded ${task.backgroundColor}`}
                ></div>
              ) : (
                <div className="hidden box ml-20 md:ml-32 rounded "></div>
              )}
            </div>

            {task.showNotes && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {taskEditId === task.id ? (
                  <div className="flex ml-1 mr-1">
                    <input
                      autoFocus
                      className="rounded mr-2 text-gray-700 pr-1 w-38 text-center"
                      value={ticketNumberEdit}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleEditSave();
                      }}
                      onClick={(event) => event.stopPropagation()}
                      onChange={handleChange}
                    />
                    <input
                      className="rounded mr-2 text-gray-700 pr-1 w-38 text-center"
                      value={ticketSubjectEdit}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleEditSave();
                      }}
                      onClick={(event) => event.stopPropagation()}
                      onChange={handleSubjectChange}
                    />
                    <button
                      className="bg-slate-400 pl-1 pr-1 rounded"
                      onClick={(event) => {
                        handleEditSave();
                        event.stopPropagation();
                      }}
                    >
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-end">
                    <span className="w-60 font-semibold mr-2">
                      {task.submitNumber + ": " + task.submitSubject}
                    </span>
                    <div
                      className={` box ml-16 md:ml-32 rounded ${task.backgroundColor} `}
                    ></div>
                  </div>
                )}

                {task.submitUrl && (
                  <div className=" justify-center">
                    <div className=" flex justify-center">
                      <a
                        className="w-24"
                        href={task.submitUrl}
                        onClick={(event) => event.stopPropagation()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Go To Ticket
                      </a>
                    </div>
                    <div className="justify-end pb-1 ">
                      <button
                        className=" bg-red-500 w-5 h-6 rounded pl-1 pr-1"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCheckboxChange(index, 1);
                        }}
                      ></button>
                      <button
                        className="bg-yellow-500 rounded w-5 h-6 ml-1 pl-1 pr-1"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCheckboxChange(index, 2);
                        }}
                      ></button>
                    </div>
                  </div>
                )}

                <TaskNotes task={task.id} usersTicketsRef={usersTicketsRef} />

                <div className="flex flex-row m-1 justify-between">
                  <button
                    className="active:scale-95 group relative px-1 justify-start rounded-sm h-7 text-center shadow-sm tracking-normal hover:bg-slate-300 hover:text-black bg-slate-200 text-gray-700 mt-1"
                    onClick={(event) => {
                      handleDelete(task.id);
                      event.stopPropagation();
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="active:scale-95 group flex ml-6 relative px-1 rounded-sm h-7 text-center shadow-sm  items-center tracking-normal hover:bg-slate-300 hover:text-black bg-slate-200 text-gray-700 mt-1"
                    onClick={(event) => {
                      handleEdit(task.id);
                      event.stopPropagation();
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="active:scale-95 group relative px-1 flex items-center rounded-sm h-7 text-center shadow-sm tracking-normal hover:bg-slate-300 hover:text-black bg-slate-200 text-gray-700 mt-1"
                    onClick={() => handleCompleteTask(task)}
                  >
                    Complete
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskList;

//<button className='p-1 bg-slate-500 mr-1 rounded'>Add Product Order</button>
