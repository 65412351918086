import React, { useState } from "react";

function ProductOrderSearch() {
  const [requestedProductOrder, setRequestedProductOrder] = useState("");

  const handleProductOrderSearch = () => {
    const productOrderLink =
      "https://edgeos.edgeconnex.com/portal/Forms/ShowRequest.aspx?ID_FINDER=" +
      requestedProductOrder +
      "&ticket_mode=product";
    window.open(productOrderLink);
    setRequestedProductOrder("");
  };

  return (
    <div className="flex flex-row h-8">
      <input
        className=" rounded-sm text-gray-800 text-center h-6 w-20 just mt-1"
        placeholder="PO"
        type="text"
        value={requestedProductOrder}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleProductOrderSearch();
        }}
        onChange={(e) => setRequestedProductOrder(e.target.value)}
      />
      <button
        className="flex bg-indigo-600 text-white hover:bg-indigo-500 rounded-sm pl-1 pr-1 text-center ml-2 h-6 mt-1"
        onClick={handleProductOrderSearch}
      >
        Open
      </button>
    </div>
  );
}

export default ProductOrderSearch;
