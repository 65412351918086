import React, { useState } from "react";

function TicketSearch() {
  const [requestedTicket, setRequestedTicket] = useState("");

  const handleTicketSearch = () => {
    const productTicketLink =
      "https://edgeos.edgeconnex.com/portal/Forms/ShowRequest.aspx?ID_FINDER=" +
      requestedTicket;
    window.open(productTicketLink);
    setRequestedTicket("");
  };

  return (
    <div className="flex flex-row h-8">
      <input
        className=" rounded-sm text-gray-800 text-center h-6 w-20 just mt-2 "
        placeholder="Ticket"
        type="text"
        value={requestedTicket}
        onKeyDown={(e) => {
          if (e.key === "Enter") handleTicketSearch();
        }}
        onChange={(e) => setRequestedTicket(e.target.value)}
      />
      <button
        className="flex bg-indigo-600 text-white hover:bg-indigo-500 rounded-sm pl-1 pr-1 text-center ml-2 mt-2 h-6 active:scale-95"
        onClick={handleTicketSearch}
      >
        Open
      </button>
    </div>
  );
}

export default TicketSearch;
