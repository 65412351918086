import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import RemoveMemberAlert from "../RemoveMemberAlert";
import TeamInviteCodeAlert from "./TeamInviteCodeAlert";
import { ToastContainer, toast } from "react-toastify";

export default function TeamMembers(props) {
  const {
    showTeamMembers,
    setShowTeamMembers,
    selectedTeam,
    userTeams,
    sitesRef,
    auth,
    setShowJoinTeamForm,
    showJoinTeamForm,
  } = props;

  const [teamMembers, setTeamMembers] = useState([]);
  const [isTeamManager, setIsTeamManager] = useState(false);
  const [removeMemberAlert, setRemoveMemberAlert] = useState(false);
  const [removeMember, setRemoveMember] = useState([]);
  const [showInviteCode, setShowInviteCode] = useState(false);
  const [inviteCode, setInviteCode] = useState("");
  const currentUser = auth.currentUser.uid;

  useEffect(() => {
    const unsubscribe = sitesRef
      .doc(userTeams[0])
      .collection("members")
      .onSnapshot((snapshot) => {
        const members = snapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          role: doc.data().role,
          uid: doc.data().uid,
        }));
        setTeamMembers(members);
      });

    sitesRef
      .doc(userTeams[0])
      .collection("members")
      .doc(currentUser)
      .get()
      .then((doc) => {
        if (doc.exists) {
          if (doc.data().role === "Manager") {
            setIsTeamManager(true);
          }
        } else {
        }
      });

    return () => {
      unsubscribe();
    };
  }, [sitesRef, userTeams, currentUser]);

  async function handleTeamMemberAdd() {
    let Code = "";
    try {
      const teamDoc = await sitesRef.doc(selectedTeam).get();
      if (teamDoc.exists) {
        Code = teamDoc.data().teamCode;
      }
      setInviteCode(Code);
    } catch (error) {
      console.error(error);
    }
    setShowInviteCode(true);
    console.log(inviteCode);
  }

  const handleTeamMemberRemove = () => {
    sitesRef
      .doc(selectedTeam)
      .collection("members")
      .doc(removeMember.id)
      .delete();
    setRemoveMemberAlert(false);
    setRemoveMember([]);
  };

  const handleRoleChange = (role, member) => {
    if (role === "Remove") {
      setRemoveMemberAlert(true);
      setRemoveMember(member);
    } else {
      sitesRef.doc(selectedTeam).collection("members").doc(member.id).update({
        role: role,
      });
      toast.success("Role Changed Successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <div>
      <Transition.Root show={showTeamMembers} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setShowTeamMembers}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel
                    className={`pointer-events-auto relative w-screen max-w-md`}
                  >
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => {
                            setShowTeamMembers(false);
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 flex justify-between sm:px-6">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Team Members
                        </Dialog.Title>
                        {userTeams.length === 0 && (
                          <div>
                            <button
                              className="text-black bg-indigo-50 hover:bg-indigo-600 w-8 h-8 text-center pl-1 hover:text-white rounded-full  hover:font-semibold active:scale-95"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Join a Team"
                              onClick={() => {
                                setShowJoinTeamForm(true);
                                setShowTeamMembers(false);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 4.5v15m7.5-7.5h-15"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <div className="absolute inset-0  px-4 sm:px-6">
                          <div className=" flex flex-col justify-center items-center  ">
                            <div className="flex  items-center mb-2">
                              <h1 className="text-2xl text-gray-700 mr-2">
                                Team Members
                              </h1>
                              {isTeamManager && (
                                <button
                                  className="rounded-full  justify-center items-center flex w-8 h-8 text-2xl hover:shadow text-gray-700 hover:bg-gray-200 hover:text-black active:scale-95"
                                  onClick={handleTeamMemberAdd}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>

                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                                  <tr>
                                    <th scope="col" className="px-6 py-3">
                                      Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                      Role
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {teamMembers.map((member, index) => (
                                    <tr
                                      key={index}
                                      className="bg-white text-gray-700 "
                                    >
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        {member.name}
                                      </td>

                                      {isTeamManager ? (
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          <select
                                            className="w-full text-gray-700 bg-white rounded-md border border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                            value={member.role}
                                            onChange={(e) => {
                                              handleRoleChange(
                                                e.target.value,
                                                member
                                              );
                                            }}
                                          >
                                            <option value={member.role}>
                                              {member.role}
                                            </option>
                                            {member.role !== "Manager" && (
                                              <option value="Manager">
                                                Manager
                                              </option>
                                            )}
                                            {member.role !== "Tech" && (
                                              <option value="Tech">Tech</option>
                                            )}
                                            {member.role !== "Rep" && (
                                              <option value="Rep">Rep</option>
                                            )}
                                            <option
                                              className="text-red-500"
                                              value="Remove"
                                            >
                                              Remove
                                            </option>
                                          </select>
                                        </td>
                                      ) : (
                                        <td className="px-6 py-4 whitespace-nowrap">
                                          {member.role}
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            <ul className="text-gray-600"></ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <RemoveMemberAlert
        removeMemberAlert={removeMemberAlert}
        setRemoveMemberAlert={setRemoveMemberAlert}
        handleTeamMemberRemove={handleTeamMemberRemove}
        removeMember={removeMember}
      />
      <TeamInviteCodeAlert
        showInviteCode={showInviteCode}
        setShowInviteCode={setShowInviteCode}
        inviteCode={inviteCode}
      />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
