import React, { useEffect, useState } from "react";
import ProductOrderSearch from "./ProductOrderSearch";
import TicketSearch from "./TicketSearch";
import { Dialog } from "@headlessui/react";

const PopupForm = (props) => {
  const {
    showForm,
    newTask,
    newSubject,
    handleSubmit,
    handleTaskChange,
    handleSubjectChange,
    setShowForm,
    tasks,
    filteredTasks,
    setFilteredTasks,
  } = props;

  const [sortType, setSortType] = useState("number");

  function handleFilter(e) {
    const filterVal = e.target.value;
    setFilteredTasks(
      tasks.filter(
        (task) =>
          task.submitNumber.toLowerCase().includes(filterVal.toLowerCase()) ||
          task.submitSubject.toLowerCase().includes(filterVal.toLowerCase())
      )
    );
  }

  function handleSort(type) {
    setSortType(type);
    let sortedFilteredTasks = [...filteredTasks];
    if (sortType === type) {
      sortedFilteredTasks.reverse();
    } else {
      if (type === "number") {
        sortedFilteredTasks.sort((a, b) =>
          a.submitNumber.localeCompare(b.submitNumber)
        );
      }
    }
    setFilteredTasks(sortedFilteredTasks);
  }

  useEffect(() => {
    setFilteredTasks(tasks);
  }, [tasks]);

  return (
    <div className={`${showForm === true ? "blur-sm" : ""}`}>
      <div className=" justify-center items-center text bg-center flex   ">
        <input
          className="w-24 h-8 text-center text-gray-700 rounded-sm mr-2"
          type="text"
          placeholder="Search"
          onChange={handleFilter}
        />
        <button
        className="text-slate-500 stroke-2 hover:text-slate-800"
        onClick={() => handleSort("number")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-Width="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
            />
          </svg>
        </button>
        <button
          className="bg-indigo-600 text-white hover:bg-indigo-500 rounded-sm p-1 ml-24 md:ml-28 mr-16 md:mr-20 content-center md:content-left md:text-lg sm:text-base active:scale-95"
          onClick={() => setShowForm(true)}
        >
          <span className="">New</span>
          <span className="hidden md:inline"> Ticket</span>
        </button>
        <div>
          <TicketSearch />
          <ProductOrderSearch />
        </div>
      </div>

      <Dialog
        open={showForm}
        onClose={() => setShowForm(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-gray-200 shadow-2xl text-center items-center">
            <Dialog.Title className="text-gray-800 font-medium my-2">
              New Ticket
            </Dialog.Title>
            <div className="m-4">
              <form
                className="flex flex-col items-center text-center "
                onSubmit={handleSubmit}
              >
                <label htmlFor="password" className="sr-only">
                  Task
                </label>
                <input
                  className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  type="text"
                  placeholder="Ticket"
                  value={newTask}
                  onChange={handleTaskChange}
                />

                <label htmlFor="password" className="sr-only">
                  Title
                </label>
                <input
                  className="relative block w-full rounded mt-1 appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  type="text"
                  placeholder="Title"
                  value={newSubject}
                  onChange={handleSubjectChange}
                />

                <button
                  className="flex bg-slate-700 hover:text-gray-800 hover:bg-slate-400 w-36 items-center justify-center text-gray rounded p-1 text-center mt-3 active:scale-95"
                  type="submit"
                >
                  Add Task
                </button>
              </form>
            </div>
            <button
              className="pr-1 pl-1 relative flex w-full justify-center rounded-md h-8 text-center shadow-sm  items-center tracking-normal  hover:text-black hover:underline hover:font-medium text-gray-600 mt-2 active:scale-125"
              onClick={() => setShowForm(false)}
            >
              Cancel
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default PopupForm;
