import React, { useState, useEffect } from 'react';

const DeliveryNotes = (props) => {
  const { delivery, 
    viewSiteDeliveriesRef,
   } = props;
  const [notes, setNotes] = useState('');

  useEffect(() => {
    const fetchNotes = async () => {
      const deliveryDoc = await viewSiteDeliveriesRef.doc(delivery.id).get();
      setNotes(deliveryDoc.data().notes);
    };
    fetchNotes();
  }, [delivery.id, viewSiteDeliveriesRef]);

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  }

  const handleNotesBlur = async (event) => {
    await viewSiteDeliveriesRef.doc(delivery.id).update({ notes: event.target.value });
  }

  return (
    <textarea className=" pl-1 notes h-20 w-96 text-gray-700" value={notes} onClick={(event) => event.stopPropagation()} onChange={handleNotesChange} onBlur={handleNotesBlur} />
  );
}

export default DeliveryNotes;