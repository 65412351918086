import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  UserGroupIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import TeamMembers from "../TeamPages/TeamMembers";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NewNavBar(props) {
  const { 
    SignOut, 
    selectedTeam,
    sitesRef,
    userTeams,
    auth,
    setShowJoinTeamForm,
    showJoinTeamForm,
  } =
    props;

  const [showTeamMembers, setShowTeamMembers] = useState(false);
  const location = useLocation();


  const navigation = [
    { name: "Open Tickets", href: "/", current: false },
    { name: "Closed Tickets", href: "/CompletedTickets", current: false },
  ];


function pageName() {
  if (location.pathname === "/") {
    return "Open Tickets";
  } else if (location.pathname === "/CompletedTickets") {
    return "Closed Tickets";
  } else if (location.pathname === "/Deliverys/" + selectedTeam || location.pathname === "/Deliverys/" + selectedTeam || location.pathname === "/PatchPanels/" + selectedTeam || location.pathname === "/OpenTickets/" + selectedTeam || location.pathname === "/CompletedTickets/" + selectedTeam) {
    return "Team Pages";
  } 
}



  return (
    <>
      <div className=" fixed w-full z-40">
        <Disclosure as="nav" className="bg-indigo-600 shadow-lg">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-8 h-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z"
                        />
                      </svg>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item, index) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              location.pathname === item.href
                                ? "bg-indigo-800 text-white"
                                : "text-white hover:bg-indigo-400 hover:text-white hover:underline",
                              "px-3 py-2 rounded-md text-sm font-medium active:scale-95"
                            )}
                            aria-current={
                              location.pathname === item.href
                                ? "page"
                                : undefined
                            }
                          >
                            {item.name}
                          </Link>
                        ))}
                        <Link
                          to={`/OpenTickets/${selectedTeam}`}
                          className={classNames(
                            location.pathname ===
                              `/OpenTickets/${selectedTeam}` ||
                              location.pathname ===
                                `/CompletedTickets/${selectedTeam}` ||
                              location.pathname ===
                                `/Deliverys/${selectedTeam}` ||
                              location.pathname ===
                                `/PatchPanels/${selectedTeam}`
                              ? "bg-indigo-800 text-white"
                              : "text-white hover:bg-indigo-400 hover:text-white hover:underline",
                            "px-3 py-2 rounded-md text-sm font-medium active:scale-95"
                          )}
                          aria-current={
                            location.pathname ===
                              `/OpenTickets/${selectedTeam}` ||
                            location.pathname ===
                              `/CompletedTickets/${selectedTeam}` ||
                            location.pathname ===
                              `/Deliverys/${selectedTeam}` ||
                            location.pathname === `/PatchPanels/${selectedTeam}`
                              ? "page"
                              : undefined
                          }
                        >
                          Team
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="md:flex items-center ">
                      <h1 className="text-white text-2xl md:hidden tracking-wide flex font-bold">
                        {pageName()}
                      </h1>
                      </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        type="button"
                        onClick={SignOut}
                        className="rounded-full bg-indigo-600 p-1 text-gray-200 hover:text-white hover:ring-2 hover:ring-white hover:ring-offset-1 hover:ring-offset-gray-800 active:scale-95"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Sign Out"
                      >
                        <span className="sr-only">View notifications</span>
                        <ArrowRightOnRectangleIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </button>

                      {/* Profile dropdown */}

                      <div className="relative ml-3">
                        <button
                          className="flex max-w-xs items-center rounded-full bg-indigo-600 text-sm hover:ring-2 hover:ring-white hover:ring-offset-1 hover:ring-offset-gray-800 active:scale-95"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="Team Menu"
                          onClick={() => setShowTeamMembers(true)}
                        >
                          <span className="sr-only">Open Team Menu</span>
                          <UserGroupIcon className="h-8 w-8 rounded-full" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-indigo-600 p-2 text-gray-100 hover:bg-indigo-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6 active:scale-95"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6 active:scale-95"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={classNames(
                        location.pathname === item.href
                          ? "bg-indigo-800 text-white"
                          : "text-white hover:bg-indigo-400 hover:text-white hover:underline",
                        "block px-3 py-2 rounded-md text-base font-medium active:scale-95"
                      )}
                      aria-current={
                        location.pathname === item.href ? "page" : undefined
                      }
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                  <Disclosure.Button
                    as={Link}
                    to={`/OpenTickets/${selectedTeam}`}
                    className={classNames(
                      location.pathname === `/OpenTickets/${selectedTeam}` ||
                        location.pathname ===
                          `/CompletedTickets/${selectedTeam}` ||
                        location.pathname === `/Deliverys/${selectedTeam}` ||
                        location.pathname === `/PatchPanels/${selectedTeam}`
                        ? "bg-indigo-800 text-white"
                        : "text-white hover:bg-indigo-400 hover:text-white hover:underline",
                      "block px-3 py-2 rounded-md text-base font-medium active:scale-95"
                    )}
                    aria-current={
                      location.pathname === `/OpenTickets/${selectedTeam}` ||
                      location.pathname ===
                        `/CompletedTickets/${selectedTeam}` ||
                      location.pathname === `/Deliverys/${selectedTeam}` ||
                      location.pathname === `/PatchPanels/${selectedTeam}`
                        ? "page"
                        : undefined
                    }
                  >
                    Team
                  </Disclosure.Button>
                </div>
                <div className="border-t border-gray-700 pt-4 pb-3">
                  <div className="flex items-center px-5">
                  <button
                          className="flex max-w-xs items-center rounded-full bg-indigo-600 text-sm hover:ring-2 hover:ring-white hover:ring-offset-1 hover:ring-offset-gray-800 active:scale-95"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="Team Menu"
                          onClick={() => setShowTeamMembers(true)}
                        >
                          <span className="sr-only">Open Team Menu</span>
                          <UserGroupIcon className="h-9 w-9 rounded-full" />
                        </button>
                    <div className="ml-3">
                      <div className=" font-medium tracking-wide text-xl leading-none text-white">
                        {selectedTeam}
                      </div>
                    </div>

                    <button
                      type="button"
                      onClick={SignOut}
                      className="active:scale-95 ml-auto flex-shrink-0 rounded-full bg-indigo-600 p-1 text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="sr-only">View notifications</span>
                      <ArrowRightOnRectangleIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <TeamMembers
        showTeamMembers={showTeamMembers}
        setShowTeamMembers={setShowTeamMembers}
        sitesRef={sitesRef}
        userTeams={userTeams}
        auth={auth}
        selectedTeam={selectedTeam}
        setShowJoinTeamForm={setShowJoinTeamForm}
        showJoinTeamForm={showJoinTeamForm}
        className={`${showJoinTeamForm === true ? 'blur-sm' : ''}`} 
      />
    </>
  );
}
