import React, { useEffect, useState } from "react";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import DeliveryNotes from "./DeliveryNotes";
import { ArrowsUpDownIcon } from "@heroicons/react/20/solid";

function DeliveryData(props) {
  const { viewSiteDeliveriesRef } = props;
  const [deliveries, setDeliveries] = useState([]);
  const [filteredDeliveries, setFilteredDeliveries] = useState([]);
  const [sortType, setSortType] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);

  useEffect(() => {
    const unsubscribe = viewSiteDeliveriesRef.onSnapshot((snapshot) => {
      const newDeliveryList = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          carrier: doc.data().carrier,
          notes: doc.data().notes,
          submitedby: doc.data().submitedby,
          ticketnumber: doc.data().ticketnumber,
          trackingnumber: doc.data().trackingnumber,
          custname: doc.data().customername,
          delivereddate: doc.data().delivereddate,
        };
      });
      setDeliveries(newDeliveryList);
    });
    return unsubscribe;
  }, [viewSiteDeliveriesRef]);

  useEffect(() => {
    setFilteredDeliveries(deliveries);
  }, [deliveries]);

  function handleFilter(e) {
    const filterVal = e.target.value;
    setFilteredDeliveries(
      deliveries.filter(
        (delivery) =>
          delivery.custName.toLowerCase().includes(filterVal.toLowerCase()) ||
          delivery.ticketnumber
            .toLowerCase()
            .includes(filterVal.toLowerCase()) ||
          delivery.trackingnumber
            .toLowerCase()
            .includes(filterVal.toLowerCase())
      )
    );
  }

  function handleSort(type) {
    setSortType(type);
    let sortedFilteredDeliveries = [...filteredDeliveries];
    if (type === sortType) {
      sortedFilteredDeliveries.reverse();
    } else {
      if (type === "custname") {
        sortedFilteredDeliveries.sort((a, b) =>
          a.custname.localeCompare(b.custname)
        );
      } else if (type === "date") {
        sortedFilteredDeliveries.sort((a, b) => a.date.localeCompare(b.date));
      }
    }
    setFilteredDeliveries(sortedFilteredDeliveries);
  }

  function toggleExpandedRow(id) {
    setExpandedRow((prevExpandedRow) => (prevExpandedRow === id ? null : id));
  }


  function ExpandedRowInfo({ delivery }) {
    return (
      <tr className="">
        <td colSpan={5}>
          <div className="flex justify-center">
            <DeliveryNotes
              delivery={delivery}
              viewSiteDeliveriesRef={viewSiteDeliveriesRef}
            />
          </div>
          <div className="flex justify-center">
            <span className="text-gray-500 text-sm">
              Recived BY:{delivery.submitedby} on:{" "}
              {delivery.delivereddate.toDate().toLocaleString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
        </td>
      </tr>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-center mt-3">
        <input
          className=" mt-2 w-72 h-8 shadow-sm text-gray-700 text-center rounded-sm "
          type="text"
          placeholder="Search"
          onChange={handleFilter}
        />
      </div>
      <div className="grid place-items-center pt-2">
        <div className="  overflow-x-auto shadow-md sm:rounded-lg">
          <table className="table-auto w-full text-sm text-left text-gray-800 ">
            <thead className="text-xs text-gray-800 uppercase bg-gray-100 shadow-bottom text-center items-center justify-center">
              <tr className="">
                <th className="px-6 py-3">
                  <div className="flex items-center justify-center flex-row">
                    Date
                    <ArrowsUpDownIcon
                      className="text-gray-600 w-4 h-4 active:scale-95 hover:text-black cursor-pointer ml-1"
                      onClick={() => handleSort("date")}
                    />
                  </div>
                </th>
                <th className="px-6 py-3">
                  <div className="flex items-center justify-center flex-row">
                    Customer
                    <ArrowsUpDownIcon
                      className="text-gray-600 w-4 h-4 active:scale-95 hover:text-black cursor-pointer ml-1"
                      onClick={() => handleSort("custname")}
                    />
                  </div>
                </th>
                <th className="px-24 py-3 ">Tracking Number</th>
                <th className="px-6 py-3">Carrier</th>
                <th className="px-6 py-3">Ticket</th>
              </tr>
            </thead>
            <tbody>
              {filteredDeliveries.map((delivery, index) => (
                <React.Fragment key={delivery.id}>
                  <tr
                    className="bg-gray-200  text-center items-center justify-center"
                    onClick={() => toggleExpandedRow(delivery.id)}
                  >
                    <td className="px-4 py-2 hover:bg-gray-100 hover:text-black border-r border-slate-300">
                      {delivery.delivereddate && delivery.delivereddate.toDate()
                        ? delivery.delivereddate
                            .toDate()
                            .toLocaleString("en-US", {
                              month: "2-digit",
                              day: "2-digit",
                              year: "2-digit",
                            })
                        : ""}
                    </td>
                    <td className=" px-4 py-2 hover:bg-gray-100 hover:text-black border-r border-slate-300">
                      {delivery.custname}
                    </td>
                    <td className=" px-4 py-2 hover:bg-gray-100 hover:text-black border-r border-slate-300">
                      {delivery.trackingnumber}
                    </td>
                    <td className=" px-4 py-2 hover:bg-gray-100 hover:text-black border-r border-slate-300">
                      {delivery.carrier}
                    </td>
                    <td className=" px-4 py-2 hover:bg-gray-100 hover:text-black border-r border-slate-300">
                      {delivery.ticketnumber}
                    </td>
                  </tr>
                  {expandedRow === delivery.id && (
                    <ExpandedRowInfo delivery={delivery} />
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DeliveryData;
