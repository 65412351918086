import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import TeamNavBar from "./TeamNavBar";

function TeamCompletedTIckets(props) {
  const { sitesRef } = props;

  const { teamId } = useParams();
  const [completedTasks, setCompletedTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState({
    id: '',
    showNotes: false,
    task: null,
    notes: null,
    url: null,
  });
  const [filteredCompletedTasks, setFilteredCompletedTasks] = useState([]);
  const teamTicketsRef = sitesRef.doc(teamId).collection("tickets");

  useEffect(() => {
    const unsubscribe = sitesRef
      .doc(teamId)
      .collection("tickets")
      .where("submitStatus", "==", 2)
      .onSnapshot((snapshot) => {
        const newCompletedTasks = snapshot.docs.map((doc) => ({
          id: doc.id,
          submitNotes: doc.data().submitNotes,
          submitNumber: doc.data().submitNumber,
          submitSubject: doc.data().submitSubject,
          submitUrl: doc.data().submitUrl,
          showNotes: false,
          completedBy: doc.data().completedBy,
          completedDate: doc.data().completedate,
          submitteddate: doc.data().submitteddate,
        }));
        const sortedCompletedTasks = newCompletedTasks.sort((a, b) => {
          return a.completedDate - b.completedDate;
        });
        setCompletedTasks(sortedCompletedTasks);
      });
    return unsubscribe;
  }, [sitesRef, teamId]);

  useEffect(() => {
    setFilteredCompletedTasks(completedTasks);
  }, [completedTasks]);


  const handleSearch = (e) => {
    const searchValue = e.target.value;
    const filteredTasks = completedTasks.filter((task) => {
      return (
        task.submitNumber
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        task.submitSubject.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    setFilteredCompletedTasks(filteredTasks);
  };


  const handleCompletedTaskClick = (task) => {
    if (selectedTask.id === task.id) {
      setSelectedTask({
        showNotes: false,
        task: null,
        notes: null,
        url: null,
      });
    } else {
      setSelectedTask({
        showNotes: true,
        id: task.id,
        notes: task.notes,
        url: task.url,
        subject: task.subject,
      });
    }
  };

  const handleReopenTask = async (task) => {
    const taskRef = sitesRef.doc(teamId).collection("tickets").doc(task.id);
    await taskRef.update({ submitStatus: 1 });
    setCompletedTasks(prevCompletedTasks => prevCompletedTasks.filter((t) => t.id !== task.id));
  };


  return (
    <div>
      <header className="bg-white shadow max-h-10">
        <TeamNavBar teamId={teamId} />
      </header>
      <main>
        <div className="flex flex-col justify-center pt-8 items-center">
        <div>
        <input
          className="w-36 h-8 text-center text-gray-700 rounded-sm"
          placeholder="Search"
          type="text"
          onChange={handleSearch}
        />
      </div>
      
          <ul className="w-600 tasklist">
            {filteredCompletedTasks.map((task, index) => (
              <li
                key={task.id}
                className={`
            flex items-center justify-center 
            ${
              selectedTask.showNotes && selectedTask.id === task.id
                ? "bg-indigo-600 text-white"
                : "bg-white text-gray-700"
            }
          hover:bg-indigo-600 hover:text-white shadow rounded-sm cursor-pointer m-3 p-2
          `}
                onClick={() => handleCompletedTaskClick(task)}
              >
                <div className="text-center mt-1 items-center">
                  <span className="font-semibold tracking-wide" >{task.submitNumber + ": " + task.submitSubject}</span>
                  {selectedTask.showNotes &&
                    selectedTask.id === task.id && (
                      <div className="flex flex-col text-center items-center">
                        {selectedTask.url && (
                          <div className="flex justify-center">
                            <a
                              className="w-24"
                              href={selectedTask.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Go To Ticket
                            </a>
                          </div>
                        )}

                        <textarea
                          className=" notes mt-3 h-40 text-gray-700 pl-1"
                          onClick={(event) => event.stopPropagation()}
                          value={selectedTask.notes}
                          readOnly
                        />

                        <div className="m-1 items-end">
                          <button
                            className="group relative px-1  rounded h-8 text-center shadow-sm tracking-normal hover:bg-slate-300 hover:text-black bg-slate-200 text-gray-700 mt-2"
                            onClick={() => handleReopenTask(task)}
                          >
                            Reopen
                          </button>
                          <div className="italic text-xs text-center font-light">
                            {" "}
                            Completed By: {task.completedBy}{" "}
                            {task.completedDate.toDate().toLocaleString()}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </main>
    </div>
  );
}
export default TeamCompletedTIckets;
