import React, { useState, useEffect } from "react";

const CompletedTasks = (props) => {
  const { auth, usersRef } = props;
  const [completedTasks, setCompletedTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState({
    showNotes: false,
    task: null,
    notes: null,
    url: null,
  });
  const [filteredCompletedTasks, setFilteredCompletedTasks] = useState([]);

  useEffect(() => {
    const unsubscribe = usersRef
      .doc(auth.currentUser.uid)
      .collection("tickets")
      .where("submitStatus", "==", 2)
      .onSnapshot((snapshot) => {
        const newCompletedTasks = snapshot.docs.map((doc) => ({
          id: doc.id,
          task: doc.data().submitNumber,
          url: doc.data().submitUrl,
          notes: doc.data().notes,
          subject: doc.data().submitSubject,
          completedate: doc.data().completedate,
        }));
        const sortedCompletedTasks = newCompletedTasks.sort((a, b) => {
          return a.completedate - b.completedate;
        });
        setCompletedTasks(sortedCompletedTasks);
      });
    return unsubscribe;
  }, [usersRef, auth.currentUser.uid]);

  useEffect(() => {
    setFilteredCompletedTasks(completedTasks);
  }, [completedTasks]);

const handleSearch = (e) => {
    const searchValue = e.target.value;
    const filteredTasks = completedTasks.filter((task) => {
      return task.task.toLowerCase().includes(searchValue.toLowerCase()) || 
      task.subject.toLowerCase().includes(searchValue.toLowerCase());
    });
    setFilteredCompletedTasks(filteredTasks);
  };


  const handleCompletedTaskClick = (task) => {
    if (selectedTask.task === task.task) {
      setSelectedTask({
        showNotes: false,
        task: null,
        notes: null,
        url: null,
      });
    } else {
      setSelectedTask({
        showNotes: true,
        task: task.task,
        notes: task.notes,
        url: task.url,
        subject: task.subject,
      });
    }
  };

  const handleReopenTask = async (task) => {
    const taskRef = usersRef
      .doc(auth.currentUser.uid)
      .collection("tickets")
      .doc(task);
    await taskRef.update({ submitStatus: 1 });
    setCompletedTasks(prevCompletedTasks => prevCompletedTasks.filter((t) => t.id !== task.id));
  };

  return (

    <div className="flex pt-10 flex-col justify-center items-center">
      <div>
        <input
          className="w-36 h-8 text-center text-gray-700 rounded-sm"
          placeholder="Search"
          type="text"
          onChange={handleSearch}
        />
      </div>
      
      <ul className="w-600 tasklist">
        {filteredCompletedTasks.map((task, index) => (
          <li
            key={task.id}
            className={`
            flex items-center justify-center 
            ${
              selectedTask.showNotes && selectedTask.task === task.task
                ? "bg-indigo-600 text-white"
                : "bg-white text-gray-700"
            }
        hover:bg-indigo-600 hover:text-white shadow rounded-sm cursor-pointer m-3 p-2
          `}
            onClick={() => handleCompletedTaskClick(task)}
          >
            <div className="text-center tracking-wide items-center">
              <span className="font-semibold">{task.task + ": " + task.subject}</span>
              {selectedTask.showNotes && selectedTask.task === task.task && (
                <div className="flex flex-col text-center items-center">
                  {selectedTask.url && (
                    <div className="flex justify-center">
                      <a
                        className="w-24"
                        href={selectedTask.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Go To Ticket
                      </a>
                    </div>
                  )}
                  <textarea
                    className=" notes h-40 text-gray-700 pl-1"
                    onClick={(event) => event.stopPropagation()}
                    value={selectedTask.notes}
                    readOnly
                  />
                  <div className="justify-between m-1 items-end">
                    <button
                      className="group flex relative px-1 rounded h-8 text-center shadow-sm  items-center tracking-normal hover:bg-slate-300 hover:text-black bg-slate-200 text-gray-700 mt-2"
                      onClick={() => handleReopenTask(task.id)}
                    >
                      Reopen
                    </button>
                  </div>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CompletedTasks;
