import { LockClosedIcon } from "@heroicons/react/20/solid";
import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Alert from "../Alert";

export default function NewSignIn(props) {
  const {
    signInWithGoogle,
    auth,
    sendPasswordResetEmail,
    sendVerificationEmail,
    saveUserToDatabase,
  } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [showRegister, setShowRegister] = useState(false);
  const [registerEmail, setRegiserEmail] = useState("");
  const [registerPass, setRegisterPass] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [successfulRegister, setSuccessfulRegister] = useState(false);

  async function handleRegisterSubmit(e) {
    e.preventDefault();
    auth
    .createUserWithEmailAndPassword(registerEmail, registerPass)
      .then((user) => {
        saveUserToDatabase(user.user.uid, registerEmail, firstName, lastName);
        sendVerificationEmail(firstName, lastName);
        setSuccessfulRegister(true);
      })
      .catch((err) => {
        setError(err.message);
      }); 

      setShowRegister(false);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (err) {
      alert("Invalid email or password");
      console.log(err.message);
    }
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    if (email === "") {
      alert("Please enter your email");
    } else {
      try {
        await sendPasswordResetEmail(email);
        setIsOpen(true);
      } catch (err) {
        alert("Invalid email");
        console.log(err.message);
      }
    }
  };


  return (
    <>
      <div
        className={`flex w-screen h-screen flex-col min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8 ${
          isOpen === true || showRegister === true ? "bg-gray-600 bg-opacity-50 blur-sm" : ""
        }`}
      >
        <div className={`space-y-8 bg-white rounded-sm shadow-xl w-96 py-4 px-7 ${isOpen === true || showRegister === true ? 'blur-2 opacity-60' : ''}`}>
          <div>
            <h2 className="  text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form
            className="mt-8"
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit(event);
            }}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-b rounded-t-xl border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-t mt-0.5 rounded-b-xl border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex mt-1 mb-7 items-center justify-end">
              <div className="text-sm">
                <a
                  href="#"
                  className="font-medium text-indigo-600 hover:text-indigo-500 hover:underline"
                  onClick={(event) => {
                    event.preventDefault();
                    handleForgotPassword(event);
                  }}
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                    aria-hidden="true"
                  />
                </span>
                Sign in
              </button>
            </div>
          </form>
          <div>
            <div className="text-divider text-gray-500 text-xs">Or</div>
            <div className="flex flex-col ">
              <button
                className="group relative flex w-full justify-center rounded-md h-8 text-center shadow-sm  items-center tracking-normal hover:bg-slate-300 hover:text-black bg-slate-200 text-gray-700 mt-2"
                onClick={signInWithGoogle}
              >
                <svg
                  className=" w-9 h-9 absolute inset-y-0 left-0 flex items-center pl-3 pb-1"
                  xmlns="http://www.w3.org/2000/svg"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                  <path d="M1 1h22v22H1z" fill="none" />
                </svg>
                Continue With Google
              </button>
              
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center text-center pt-8">
          <p className="text-gray-600 mr-2">
            Don't have an account?{" "}
          </p>
            <a 
            onClick={(event) => {setShowRegister(true)}}
            className="text-indigo-600 hover:text-indigo-500 hover:underline">
              Sign up
            </a>
        </div>


      </div>
      <Dialog
      open={showRegister}
      onClose={() => setShowRegister(false)}
        
        className="relative z-50"
      >
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-gray-200 shadow-2xl text-center items-center">
            <Dialog.Title className="text-gray-800 my-2">
              Register
            </Dialog.Title>
            <div className="m-3">
            <form
            className="my-2"
            onSubmit={handleRegisterSubmit}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <label htmlFor="first-name" className="sr-only">
                  First name
                </label>
                <input
                  id="first-name"
                  name="first-name"
                  type="firstName"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
              
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="First Name"
                />
              </div>
              <div>
                <label htmlFor="last-name" className="sr-only">
                  Last name
                </label>
                <input
                  id="last-name"
                  name="last-name"
                  type="lastName"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  
                  required
                  className="relative block w-full appearance-none rounded-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Last Name"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  value={registerEmail}
                  onChange={e => setRegiserEmail(e.target.value)}
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none  border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={registerPass}
                  onChange={e => setRegisterPass(e.target.value)}
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md  border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group mt-2 relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  
                </span>
                Register
              </button>
              <button 
              className="pr-1 pl-1 relative flex w-full justify-center rounded-md h-8 text-center shadow-sm  items-center tracking-normal  hover:text-black hover:underline hover:font-medium text-gray-600 mt-2"
              onClick={() => setShowRegister(false)}
              >
                Cancel
              </button>
            </div>
          </form>
          </div>
          </Dialog.Panel>
        </div>
      </Dialog>

       <Alert triggerOpen={isOpen} setTriggerOpen={setIsOpen} alertTitle={'Password Reset Request Sent!'} alertMessage={'Please check your email for a link to reset your password.'} />
       <Alert triggerOpen={successfulRegister} setTriggerOpen={setSuccessfulRegister} alertTitle={'Registration Successful!'} alertMessage={'Please check your email for a link to verify your account.'} />



    </>
  );
}
