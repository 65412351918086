import React, { useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Dialog } from "@headlessui/react";

function DeliveryForm(props) {
  const { setShowDeliveryForm, viewSiteDeliveriesRef, auth, showDeliveryForm, getUsersFirstName } =
    props;

  const [newTrackingNumber, setNewTrackingNumber] = useState("");
  const [newClient, setNewClient] = useState("");
  const [newTicketNumber, setNewTicketNumber] = useState("");
  const [newNotes, setNewNotes] = useState("");
  const [newCarrier, setNewCarrier] = useState("");
  const [newSite, setNewSite] = useState("POR01");

  const handleDeliverySubmit = async (event) => {
    event.preventDefault();
    const name = await getUsersFirstName(auth.currentUser.uid);
    const date = firebase.firestore.FieldValue.serverTimestamp()
  
    if (
      newTrackingNumber !== "" &&
      newClient !== "" &&
      newTicketNumber !== "" &&
      newCarrier !== ""
    ) {
      const delivery = {
        trackingnumber: newTrackingNumber,
        ticketnumber: newTicketNumber,
        notes: newNotes,
        carrier: newCarrier,
        customername: newClient,
        delivereddate: date,
        submitedby: name,
      };
      await viewSiteDeliveriesRef.add(delivery);
      setShowDeliveryForm(false);
      setNewCarrier("");
      setNewClient("");
      setNewTicketNumber("");
      setNewNotes("");
      setNewSite("POR01");
      setNewTicketNumber("");
    } else {
      alert("Please enter all information");
    }
    
  };

  return (
    <div>
      <Dialog
        open={showDeliveryForm}
        onClose={() => setShowDeliveryForm(false)}
        className="z-0"
      >
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-gray-200 shadow-2xl text-center items-center">
            <Dialog.Title className="text-gray-800 font-medium my-2">
              New Delivery
            </Dialog.Title>
            <div className=" m-4">
              <form
                className="flex flex-col gap-4 items-center text-center text-white "
                onSubmit={handleDeliverySubmit}
              >
                <label htmlFor="site" className="sr-only">
                  Site
                </label>
                <select
                  className="m-1 text-gray-800 h-8 text-center rounded pt-1"
                  value={newSite}
                  onChange={(e) => setNewSite(e.target.value)}
                >
                  <option value="POR01">POR01</option>
                  <option value="POR02">POR02</option>
                </select>

                <div className="flex gap-4 ">
                  <label htmlFor="trackingnumber" className="sr-only">
                    Tracking Number:
                  </label>
                  <input
                    className="relative block w-full appearance-none rounded border hover:border-gray-600 border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    type="text"
                    value={newTrackingNumber}
                    onChange={(e) => setNewTrackingNumber(e.target.value)}
                    placeholder="Tracking Number"
                  />

                  <label htmlFor="client" className="sr-only">
                    Client
                  </label>
                  <input
                    className="relative block w-full appearance-none rounded border hover:border-gray-600 border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    type="text"
                    value={newClient}
                    onChange={(e) => setNewClient(e.target.value)}
                    placeholder="Client"
                  />
                </div>

                <div className="flex gap-4">
                  <label htmlFor="ticketnumber" className="sr-only">
                    Ticket Number
                  </label>
                  <input
                    className="relative block w-full appearance-none rounded border hover:border-gray-600 border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    type="text"
                    value={newTicketNumber}
                    onChange={(e) => setNewTicketNumber(e.target.value)}
                    placeholder="Ticket Number"
                  />

                  <label htmlFor="carrier" className="sr-only">
                    Carrier
                  </label>
                  <input
                    className="relative block w-full appearance-none rounded border hover:border-gray-600 border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    type="text"
                    value={newCarrier}
                    onChange={(e) => setNewCarrier(e.target.value)}
                    placeholder="Carrier"
                  />
                </div>

                <div className="">
                  <label htmlFor="notes" className="sr-only">
                    Notes
                  </label>
                  <textarea
                    className="relative block w-full appearance-none rounded border hover:border-gray-600 border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    type="text"
                    value={newNotes}
                    onChange={(e) => setNewNotes(e.target.value)}
                    placeholder="Notes"
                  />
                </div>

                <button
                  className="flex bg-slate-700 hover:text-gray-800 hover:bg-slate-400 w-36 items-center justify-center text-gray rounded p-1 text-center mt-3 active:scale-95"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
            <button
              className="pr-1 pl-1 relative flex w-full justify-center rounded-md h-8 text-center shadow-sm  items-center tracking-normal active:scale-95 hover:text-black hover:underline hover:font-medium text-gray-600 mt-2"
              onClick={() => setShowDeliveryForm(false)}
            >
              Cancel
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
}

export default DeliveryForm;
