import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

function RemoveMemberAlert(props) {
  const {  
    handleTeamMemberRemove,
    removeMemberAlert,
    setRemoveMemberAlert,
    removeMember,
} =
    props;

    const closeModal = () => {
        setRemoveMemberAlert(false)
    }



  return (
    <div>
      <Transition appear show={removeMemberAlert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={closeModal}
        >
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as="div"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-gray-900"
                  >
                    Remove Member
                  </Dialog.Title>
                  <div className="mt-2">
                    <svg
                      aria-hidden="true"
                      className="mx-auto mb-4 text-red-600 w-14 h-14"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <p className="text-sm text-gray-500 pt-4">
                      Are you sure you want to remove {removeMember.name} from this
                      team?
                    </p>
                  </div>

                  <div className="flex justify-center mt-3 ">
                    <button
                      type="button"
                      className="inline-flex justify-center mr-3 rounded border border-transparent bg-red-700 px-4 py-2 text-sm font-medium text-gray-200 hover:text-white hover:bg-red-500 active:scale-95"
                      onClick={handleTeamMemberRemove}
                    >
                      Yes, I'm sure
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded border border-indigo-300-300 bg-indigo-600 px-4 py-2 text-sm font-medium text-gray-200 hover:text-white hover:bg-indigo-400 active:scale-95"
                      onClick={closeModal}
                    >
                      No, cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default RemoveMemberAlert;
