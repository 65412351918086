import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
import Alert from "../Alert";

function JoinTeamForm(props) {
  const {
    showJoinTeamForm,
    setShowJoinTeamForm,
    auth,
    sitesRef,
    getUsersFirstName,
    usersRef,
    arrayUnion,
  } = props;

  const [joinTeam, setJoinTeam] = React.useState("");
  const [inviteCode, setInviteCode] = React.useState("");
  const [joinedTeamAlert, setJoinedTeamAlert] = React.useState(null);

  useEffect(() => {
    if (joinedTeamAlert === false) {
      setShowJoinTeamForm(false);
    }
  }, [joinedTeamAlert, setShowJoinTeamForm]);


  const handleJoinTeam = async (event) => {
    let teamName = joinTeam.toUpperCase();
    event.preventDefault();
    const uid = auth.currentUser.uid;
    const name = await getUsersFirstName(uid);
    console.log(name);
    const site = await sitesRef.doc(teamName).get();
    if (site.exists) {
      const { teamCode: siteInviteCode } = site.data();
      if (siteInviteCode === inviteCode) {
        await sitesRef.doc(teamName).collection("members").doc(uid).set({
          role: "rep",
          name: name,
        });
        await usersRef.doc(uid).update({
          teams: arrayUnion(teamName),
        });
        setJoinedTeamAlert(true);
      } else {
        alert("Invite code is incorrect");
      }
    } else {
      alert("Team does not exist");
    }
  };

  return (
    <div>
      {joinedTeamAlert && (
        <Alert 
        className="fixed z-50"
        triggerOpen={joinedTeamAlert} 
        setTriggerOpen={setJoinedTeamAlert} 
        alertMessage={"You have joined" +
        joinTeam.toUpperCase() +
        "! Please refresh the page to access team pages."} 
        alertTitle='Joined Team Successfully!' />
      )}
      <Dialog
        open={showJoinTeamForm}
        onClose={() => setShowJoinTeamForm(false)}
        className="relative z-50"
      >
        <div className="fixed z-0 inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-gray-200 shadow-2xl text-center items-center">
            <Dialog.Title className="text-gray-800 font-medium my-2">
              Join A Team
            </Dialog.Title>
            <div className="m-4">
              <form
                className="flex flex-col items-center text-center "
                onSubmit={handleJoinTeam}
              >
                <label htmlFor="password" className="sr-only">
                  Team
                </label>
                <input
                  className="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  type="text"
                  placeholder="Team"
                  value={joinTeam}
                  onChange={(e) => {
                    setJoinTeam(e.target.value);
                  }}
                />

                <label htmlFor="password" className="sr-only">
                  Invite Code
                </label>
                <input
                  className="relative block w-full rounded mt-1 appearance-none border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  type="text"
                  placeholder="Invite Code"
                  value={inviteCode}
                  onChange={(e) => {
                    setInviteCode(e.target.value);
                  }}
                />

                <button
                  className="flex bg-slate-700 hover:text-gray-800 hover:bg-slate-400 w-36 items-center justify-center text-gray rounded p-1 text-center mt-3 "
                  type="submit"
                >
                  Join
                </button>
              </form>
            </div>
            <button
              className="pr-1 pl-1 relative flex w-full justify-center rounded-md h-8 text-center shadow-sm  items-center tracking-normal  hover:text-black hover:underline hover:font-medium text-gray-600 mt-2"
              onClick={() => setShowJoinTeamForm(false)}
            >
              Cancel
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
      
    </div>
  );
}

export default JoinTeamForm;
